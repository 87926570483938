<template>
  <div id="home">
    <div
      class="entranceBackground"
      :style="{
        backgroundColor: backgroundColor,
        backgroundImage: 'url(' + entranceBackground + ')',
      }"
    ></div>

    <button
      @click="toLogin"
      class="btn"
      :style="{ backgroundColor: primaryColor }"
    >
      前往预报名
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Home",
  computed: {
    ...mapState({
      primaryColor: (state) => state.application.properties.primaryColor,
      entranceBackground: (state) =>
        state.application.properties.entranceBackground,
      background: (state) => state.application.properties.background,
      backgroundColor: (state) => state.application.properties.backgroundColor,
    }),
  },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
  },
  mounted() {
    //没有入口图，取消入口页直接跳转登录页
    if (!this.entranceBackground) {
      this.toLogin();
    }
  },
};
</script>

<style scoped lang="scss">
#home {
  display: flex;
  align-items: center;
  flex-direction: column;
  .entranceBackground {
    width: 1200px;
    height: 687px;
    // background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .btn {
    margin: 30px 0;
    width: 320px;
    height: 40px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    line-height: 28px;
    letter-spacing: 10px;
    border: none;
    outline: none;
  }
}
</style>
